import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { Button, Container, Grid } from '@mui/material';
import { EuModal } from './EuModal';

const Wrapper = styled.div`
  background-color: white;
  padding: 0.5rem 0;
  position: fixed;
  width: 100%;
  bottom: 0;
`;

export const EU = () => {
  const [open, handleClose] = useState(false);
  return (
    <Wrapper>
      <Container maxWidth={'lg'}>
        <Grid container justifyContent={'center'} alignItems={'center'}>
          <img src="../eu.png" alt="European Union logo" height="50px" />
          <Button
            variant={'outlined'}
            size={'small'}
            onClick={() => handleClose(true)}
          >
            Project info
          </Button>
          <EuModal open={open} handleClose={() => handleClose(false)} />
        </Grid>
      </Container>
    </Wrapper>
  );
};
