import * as React from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ReactNode } from 'react';

const Prefix = styled.div`
  width: 11px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  left: 0px;
  top: 10px;

  background: ${(props) => props.theme.palette.primary.main};
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
`;

interface SectionHeaderProps {
  title: string;
  align?: 'center' | 'left' | 'right';
  className?: any;
  subheader?: string | ReactNode;
  prefix?: boolean;
  mb?: number;
  mt?: number;
}

export const SectionHeader = ({
  title,
  align = 'center',
  className,
  subheader,
  prefix = true,
  mt,
  mb = 4,
}: SectionHeaderProps) => {
  return (
    <>
      <Box
        className={className}
        justifyContent={align}
        alignItems={'center'}
        display={'flex'}
        mb={mb}
        mt={mt}
      >
        {prefix && <Prefix />}
        <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
          <Typography
            ml={1}
            variant={'h4'}
            fontWeight={'bold'}
            color={'secondary'}
          >
            {title}
          </Typography>
          {subheader && (
            <Typography
              ml={1}
              color={'secondary'}
              variant={'h6'}
              fontWeight={'normal'}
            >
              {subheader}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};
