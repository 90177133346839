import styled from 'styled-components';
import { SectionHeader } from '../SectionHeader';
import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { List, ToggleButtonGroup } from '@mui/material';
import {
  adminCategoriesFeatures,
  adminFeatures,
  adminListingFeatures,
  adminUserFeatures,
  categoriesFeatures,
  userFeatures,
  userListingFeatures,
} from './allFeatures';
import FeatureItems from './FeatureItems';
import { EtrdToggleButton } from '../../ui-kit/EtrdToggleButtonGroup';
import Typography from '@mui/material/Typography';

const Wrapper = styled.div`
  padding: 5rem 0;
`;

const FeaturesTable = () => {
  const [userType, setUserType] = useState('admin');

  const handleChange = (event, newUserType) => {
    if (newUserType !== null) {
      setUserType(newUserType);
    }
  };

  return (
    <Wrapper>
      <SectionHeader
        title="All features"
        subheader={
          <Typography mb={4} align={'center'} color={'secondary'}>
            All features are included in every plan.
          </Typography>
        }
      />

      <Box justifyContent={'center'} display={'flex'} width={'100%'}>
        <ToggleButtonGroup
          color="primary"
          value={userType}
          exclusive
          onChange={handleChange}
        >
          <EtrdToggleButton value="admin">Admin (You)</EtrdToggleButton>
          <EtrdToggleButton value="user">User</EtrdToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Box mt={10}>
        <List>
          {userType === 'user' ? (
            <>
              <FeatureItems title="LISTING" features={userListingFeatures} />
              <FeatureItems title="USER" features={userFeatures} />
              <FeatureItems title="CATEGORIES" features={categoriesFeatures} />
            </>
          ) : (
            <>
              <FeatureItems title="LISTING" features={adminListingFeatures} />
              <FeatureItems title="USER" features={adminUserFeatures} />
              <FeatureItems
                title="CATEGORIES"
                features={adminCategoriesFeatures}
              />
              <FeatureItems title="OTHERS" features={adminFeatures} />
            </>
          )}
        </List>
      </Box>
    </Wrapper>
  );
};

export default FeaturesTable;
