import * as React from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { Link } from '@mui/material';
import { EtrdButton } from '../../ui-kit/EtrdButton';

const CustomPriceWrapper = styled.div`
  width: 100%;
  padding: 2rem;
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  overflow: hidden;
  position: relative;
  margin-bottom: 3rem;
  background-color: ${(props) => props.theme.palette.primary.light};

  .MuiTypography-root {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

export const CustomPrice = () => {
  return (
    <CustomPriceWrapper>
      <Typography variant="h5" fontWeight={'bold'}>
        Custom solution
      </Typography>
      <Typography variant="subtitle1" fontWeight={'normal'} mb={3}>
        Do you have a business to scale? Have any custom needs that needs to be
        filled in? You are operating in a bigger volumes? Your organization is
        non-profit?
      </Typography>
      <Link href="/#contact">
        <EtrdButton color={'primary'} variant={'contained'}>
          Contact us for a quote
        </EtrdButton>
      </Link>
    </CustomPriceWrapper>
  );
};
