import * as React from 'react';
import Typography from '@mui/material/Typography';
import { EtrdButton } from '../../ui-kit/EtrdButton';
import { Link } from 'gatsby';
import Box from '@mui/material/Box';

export const ActionText = () => {
  return (
    <>
      <Typography color={'primary'} fontWeight={'bold'} variant={'h3'}>
        Marketplace builder <br /> for your community
      </Typography>
      <Typography mt={2} mb={4} fontWeight={'normal'} variant={'h5'}>
        Create a safe business trading space.
        <br /> No-code. Just in a few clicks.
      </Typography>
      <Box display={'flex'} alignItems={'center'}>
        <Link to="#product">
          <EtrdButton size={'large'} color={'secondary'} variant={'outlined'}>
            CHECK BENEFITS
          </EtrdButton>
        </Link>
        <Box display={'flex'} alignItems={'center'} ml={3}>
          <a
            href="https://www.producthunt.com/posts/8trade?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-8trade"
            target="_blank"
            style={{ height: '54px' }}
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=361553&theme=neutral"
              alt="8trade - Launch&#0032;your&#0032;own&#0032;business&#0032;marketplace&#0032;without&#0032;coding&#0033; | Product Hunt"
              style={{ width: '250px', height: '54px' }}
              width="250"
              height="54"
            />
          </a>
        </Box>
      </Box>
    </>
  );
};
