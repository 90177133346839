import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import Container from '@mui/material/Container';
import { SectionHeader } from '../SectionHeader';
import Box from '@mui/material/Box';
import { ToggleButtonGroup } from '@mui/material';
import Typography from '@mui/material/Typography';
import { EtrdToggleButton } from '../../ui-kit/EtrdToggleButtonGroup';

const Wrapper = styled.div`
  background-color: #f9f9fb;
  //padding: 5rem 0;
  //padding-top: 0;
  padding-bottom: 10rem;
  padding-top: 10rem;
`;

export const UseCase = () => {
  const [type, setType] = useState('exchange');

  const handleChange = (event, newType) => {
    setType(newType);
  };

  return (
    <Wrapper id="usecases">
      <Container maxWidth={'md'}>
        <SectionHeader title="How you can use 8trade" />

        <Box justifyContent={'center'} display={'flex'} width={'100%'} mb={3}>
          <ToggleButtonGroup
            color="primary"
            value={type}
            exclusive
            onChange={handleChange}
          >
            <EtrdToggleButton value="exchange">
              Trading business groups
            </EtrdToggleButton>
            <EtrdToggleButton value="wholesalers">Local community</EtrdToggleButton>
            <EtrdToggleButton value="chamber">
              Chamber of commerce
            </EtrdToggleButton>
            <EtrdToggleButton value="more">Much more...</EtrdToggleButton>
          </ToggleButtonGroup>
        </Box>

        {type === 'exchange' ? (
          <Typography variant={'h6'} fontWeight={'normal'} color={'primary'}>
            Empower your business Linkedin or Facebook groups members with a way
            to network and access the B2B offers that’re important to them.
            Without unnecessary social media walls noise, give them a web-based
            platform, accessible anywhere at any time. <br />
            <br />
            Leverage the community potential and conduct more business trade.{' '}
            <br />
            <br />
            Improve outreach by connecting with others using the 8trade network
            ecosystem, encouraging member networking in a rewarding way that
            drives business and eventually help closing deals.
          </Typography>
        ) : type === 'wholesalers' ? (
          <Typography variant={'h6'} fontWeight={'normal'} color={'primary'}>
            Community marketplaces provide people with the resources they need to live better and healthier lives. 
            <br />
            <br />
            Local buyers and sellers can subscribe to your marketplace and be up to date with the latest offerings, which helps keep more dollars local by directly supporting small businesses in the community.
          </Typography>
        ) : type === 'chamber' ? (
          <Typography variant={'h6'} fontWeight={'normal'} color={'primary'}>
            Easily manage members, organizations, and individuals associated in
            your chamber. Inform your members about any important news for your
            organization. <br /> <br /> Strengthen memberships using digital
            trade space. Target organizations and individuals. Do more in less
            time and exceed expectations.
          </Typography>
        ) : (
          <Typography variant={'h6'} fontWeight={'normal'} color={'primary'}>
            Of course, those examples are just a brief understanding of how you,
            as a business can utilize our solution. <br /> <br />
            It can be used in almost infinite cases. You can decide how you want
            to set it up and for who. That's the beauty of 8trade's flexibility.
          </Typography>
        )}
      </Container>
    </Wrapper>
  );
};
