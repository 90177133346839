import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Html } from '@mui/icons-material';

const description =
  'Build and publish marketplace to a large audience of buyers and sellers, without coding. No technical skills required.';
const title = '8trade - team';

export const TeamSEO = () => {
  return (
    <Helmet
      title="8trade"
      titleTemplate={title}
      htmlAttributes={{
        lang: 'en',
      }}
    >
      <Html lang="en" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content="../meta-image.png" />
      <meta property="og:url" content="https://8tra.de" />
      <meta property="og:type" content="website" />
      <meta name="image" content="../meta-image.png" />
      <meta name="description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content="../meta-image.png" />
    </Helmet>
  );
};
