import * as React from 'react';
import { Dialog, DialogContent, Typography } from '@mui/material';

export const EuModal = ({ open, handleClose }: any) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      // TransitionComponent={Transition}
    >
      <DialogContent>
        <Typography variant={'h6'} fontWeight={'bold'}>
          Project description
        </Typography>
        <Typography mb={3}>
          Development of an innovative web tool for the automated B2B
          marketplace builder platform in the SaaS model along with marketplace
          network feature.
        </Typography>

        <Typography variant={'h6'} fontWeight={'bold'}>
          Purpose of the project
        </Typography>
        <Typography mb={3}>
          Introducing the 8trade product innovation to the market - a web
          software for the management and administration of the closed
          marketplaces in the B2B model.
        </Typography>

        <Typography variant={'h6'} fontWeight={'bold'}>
          Planned effects
        </Typography>
        <Typography mb={3}>
          Web software that can be offered in the SaaS model. Automate the
          marketplace development process, as well as the possibility of
          associating business groups with similar topics and automatic offer
          matching process.
        </Typography>

        <Typography variant={'h6'} fontWeight={'bold'}>
          Contribution of European Funds
        </Typography>
        <Typography>999 973,99 PLN</Typography>
      </DialogContent>
    </Dialog>
  );
};
