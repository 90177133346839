export const userListingFeatures = [
  {
    text: 'Buy and sell listing posting',
  },
  {
    text: 'Listing edition / hiding',
  },
  {
    text: `Listings search`,
  },
  {
    text: 'Listings filtering by attributes and listing types',
  },
  {
    text: 'Listing  page',
  },
  {
    text: 'Contact listing owner form',
  },
];

export const userFeatures = [
  {
    text: 'User account / settings',
  },
  {
    text: 'User profile page',
  },
  {
    text: `User company details`,
  },
  {
    text: `Users' recommendations`,
  },
  {
    text: `Download subscription invoices from Stripe`,
  },
];

export const categoriesFeatures = [
  {
    text: 'Posting listing under specific category',
  },
];

export const adminListingFeatures = [
  {
    text: 'All listings moderation',
  },
];

export const adminUserFeatures = [
  {
    text: 'All members details',
  },
  {
    text: 'Member verification',
  },
  {
    text: 'Member blocking functionality',
  },
];

export const adminCategoriesFeatures = [
  {
    text: 'Category tree creation',
  },
  {
    text: 'Category attributes creation',
  },
  {
    text: 'Different attributes types (multi choice, single choice) creation',
  },
  {
    text: 'Pinning favorites 3 categories to navigation bar',
  },
];

export const adminFeatures = [
  {
    text: 'Stripe integration for receiving subscription fees from users',
  },
  {
    text: 'Marketplace basic settings like marketplace logo, name, slogan, description, promotion banner image',
  },
  {
    text: 'Marketplace subscription plans creation',
  },
  {
    text: 'Marketplace subscription plans moderation',
  },
  {
    text: 'Multi-Lingual',
  },
  {
    text: 'Announcements with title and description that can be pinned on top of the marketplace landing page',
  },
];
