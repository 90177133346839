export const testimonialsList = [
  {
    name: 'Namatirai Zinyohwera',
    position: 'Founder',
    company: 'Free Trade Zone',
    image: '/testimonials/namatirai-zinyohwera.svg',
    testimonial:
      "The design thinking behind 8trade has ensured not just an optimum product-market for developed markets, but more importantly for us, suitability for Africa's burgeoning e-commerce opportunity. This has made aligning our Free Trade Zone (FTZ) go-to-market strategy with 8trade seamless and affordable. Through this partnership we seek to unlock customer centric value on the back of future-proof innovation and technology.",
  },
  {
    name: 'Piotr Wolny',
    position: 'Managing Director',
    company: 'The Northern Chamber of Commerce',
    image: '/testimonials/piotr-wolny.svg',
    testimonial:
      'We were looking for a solution for an in-house offer exchange platform for our members. 8trade offers a solution that not only meets our needs but even offers more, with zero pre-investment. 8trade is helping us with any settings and always giving us pro tips.',
  },
  {
    name: 'Marek Świeczkowski',
    position: 'Chairman of the Board',
    company: 'North-South Logistics and Transport Cluster',
    image: '/testimonials/marcin-swieczkowski.svg',
    testimonial:
      "We're, bringing together many entrepreneurs, haven't had yet the opportunity to allow a business exchange. Our companies were looking for contractors even on the other side of the world. Thanks to 8trade, we can connect them and give them the possibility to showcase their services within the cluster.",
  },
];
