import * as React from 'react';
import { Button, ButtonProps } from '@mui/material';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  &.MuiButton-outlined {
    border-width: 2px;
  }
  &.MuiButton-outlinedSecondary {
    border-color: ${(props) => props.theme.palette.secondary.main};
  }
  &.MuiButton-outlinedPrimary {
    border-color: ${(props) => props.theme.palette.primary.main};
  }
`;

export const EtrdButton = (props: ButtonProps) => {
  return (
    <StyledButton
      disableElevation
      variant={'contained'}
      disableFocusRipple
      disableRipple
      {...props}
    >
      {props.children}
    </StyledButton>
  );
};
