import * as React from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { Grid, Link } from '@mui/material';
import { EtrdButton } from '../../ui-kit/EtrdButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const EarlyAdoptersWrapper = styled.div`
  width: 100%;
  padding: 2rem;
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  overflow: hidden;
  position: relative;

  background-color: ${(props) => props.theme.palette.primary.light};

  .MuiTypography-root {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

export const EarlyAdopters = () => {
  return (
    <EarlyAdoptersWrapper>
      <Typography fontWeight={'medium'}>Become our early adopter</Typography>
      <Typography variant="h5" fontWeight={'bold'} mb={3}>
        One Month Free Trial
      </Typography>
      <Grid container>
        <Grid item md={6} sm={12}>
          <Box display={'flex'} alignContent={'center'} mb={2}>
            <CheckCircleIcon
              color={'primary'}
              fontSize={'small'}
              alignmentBaseline={'baseline'}
            />
            <Typography ml={2}>Lifetime subscription discount</Typography>
          </Box>
        </Grid>

        <Grid item md={6} sm={12}>
          <Box display={'flex'} alignContent={'center'} mb={2}>
            <CheckCircleIcon
              color={'primary'}
              fontSize={'small'}
              alignmentBaseline={'baseline'}
            />
            <Typography ml={2}>Early access to Beta features</Typography>
          </Box>
        </Grid>
        <Grid item md={6} sm={12}>
          <Box display={'flex'} alignContent={'center'} mb={2}>
            <CheckCircleIcon
              color={'primary'}
              fontSize={'small'}
              alignmentBaseline={'baseline'}
            />
            <Typography ml={2}>Close colaboration with the team</Typography>
          </Box>
        </Grid>
        <Grid item md={6} sm={12}>
          <Box display={'flex'} alignContent={'center'} mb={2}>
            <CheckCircleIcon
              color={'primary'}
              fontSize={'small'}
              alignmentBaseline={'baseline'}
            />
            <Typography ml={2}>
              Your business promotion across platform
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Link href="/pricing">
        <EtrdButton
          color={'primary'}
          variant={'text'}
          endIcon={<ArrowRightAltIcon />}
        >
          Join now
        </EtrdButton>
      </Link>
    </EarlyAdoptersWrapper>
  );
};
