import * as React from 'react';
import AppWrapper from '../components/AppWrapper';
import styled from 'styled-components';
import Container from '@mui/material/Container';
import { TeamMember } from '../components/Team/TeamMember';
import { Masonry } from '@mui/lab';
import Typography from '@mui/material/Typography';
import { TeamSEO } from '../components/SEO/TeamSEO';

const Wrapper = styled.div`
  background-color: #f9f9fb;
  padding: 5rem 0;
`;

const HeaderWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.main};
  padding: 5rem 0;
  margin-top: 64px;
`;

const Team = () => {
  return (
    <AppWrapper>
      <TeamSEO />
      <HeaderWrapper>
        <Container maxWidth={'lg'}>
          <Typography
            variant={'h6'}
            fontWeight={'medium'}
            color={'white'}
            align={'center'}
            mb={3}
          >
            We were united by a strong feeling...
          </Typography>
          <Typography
            variant={'h6'}
            fontWeight={'medium'}
            color={'white'}
            align={'center'}
            mb={3}
          >
            The feeling that we can create something valuable, something good
            that will change (someone's) world. This feeling made us survive all
            encountered adversities. Today we can confidently say that we have
            such a solution.
          </Typography>

          <Typography
            variant={'h6'}
            fontWeight={'medium'}
            color={'white'}
            align={'center'}
            mb={3}
          >
            We ignited our ambitions and desire for more. We are united by
            willingness creating a business in a special way. We have a company
            vision that grows with customers. A company that cares about the
            development of each members of our community.
          </Typography>

          <Typography
            variant={'h6'}
            fontWeight={'medium'}
            color={'white'}
            align={'center'}
          >
            A company that crosses the beaten borders and sets new paths.
          </Typography>
        </Container>
      </HeaderWrapper>
      <Wrapper>
        <Container maxWidth={'lg'}>
          <Masonry
            columns={{ xs: 1, sm: 2, md: 3 }}
            spacing={{ xs: 2, sm: 1, md: 4 }}
          >
            <TeamMember
              name="Adam Mocarski"
              title="Business ∙ Design ∙ Strategy ∙ Marketing ∙ Sales"
              description="Experienced as an UI Lead and Product Manager. Defining UI/UX principles in order to achieve the best results. Worked in DeFi and Web3 environment. Worked with Lenovo US. Graduated in
                  Computer Science. Won ETH Lisbon 21 hackathon."
              image="adam"
              linkedin="https://www.linkedin.com/in/adammocarski/"
            />
            <TeamMember
              name="Marek Drozdowski"
              title="AI ∙ Business ∙ Strategy"
              description="
Worked at Nvidia, managed fast-prototyping team in US-based startup.
Two patent applications in AI industry.
Using his analytical skills to evaluate business, scale up and build processes.
"
              image="marek"
              linkedin="https://www.linkedin.com/in/marek-drozdowski-436b6610a/"
            />
            <TeamMember
              name="Kacper Borowski"
              title="PO ∙ Software"
              description="
Man from the borderline of business and technology. Experience in design and development of CMS and e-commerce systems. Developed a system handling NGS processes for laboratories that e.g. fight cancer or genomic diseases.
"
              image="kacper"
              linkedin="https://www.linkedin.com/in/kacper-borowski-a0a89915b/"
            />
            <TeamMember
              name="Kornel Kędzierski"
              title="Backend ∙ Infra"
              description="
Backend ninja with over 12 years of commercial experience. Blockchain technology enthusiast.
"
              image="kornel"
              linkedin="https://www.linkedin.com/in/kornel-k%C4%99dzierski-393a904a/"
            />
            <TeamMember
              name="Wiktor Kuśmierek"
              title="Operations ∙ Sales"
              description="
Experienced in B2B trading with many years of marketing and office management experience.
"
              image="wiktor"
              linkedin="https://www.linkedin.com/in/wiktor-ku%C5%9Bmierek-137237207/"
            />
            <TeamMember
              name="Mikołaj Piotrowicz"
              title="Frontend"
              description="
Former frontend lead in a crypto exchange project that was used by over 500k users every month. Graduated in Computer Science.
"
              image="mikolaj"
              linkedin="https://www.linkedin.com/in/miko%C5%82aj-piotrowicz-9696a0128/"
            />
            {/*            <TeamMember*/}
            {/*              name="Chris Kraszewski"*/}
            {/*              title="Bizdev"*/}
            {/*              description="*/}
            {/*Former JS dev with past working experience in Netguru. Cofounder of WAYF DIGITAL - agency that brings business and software for clients like RAMP.*/}
            {/*"*/}
            {/*              image="chris"*/}
            {/*            />*/}
          </Masonry>
        </Container>
      </Wrapper>
    </AppWrapper>
  );
};

export default Team;
