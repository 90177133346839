import * as React from 'react';
import styled from 'styled-components';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

const Wrapper = styled.div`
  background-color: #f9f9fb;
  padding: 5rem 0;
`;

const StyledStep = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 32px;
  gap: 10px;

  width: 60px;
  height: 60px;

  background: #5e68a1;
  border-radius: 12px;

  flex: none;
  order: 0;
  flex-grow: 0;
`;

const Step = ({ number, text }: { number: number; text: string | any }) => {
  return (
    <Box display={'flex'} alignItems={'center'} mb={3}>
      <StyledStep>
        <Typography variant={'h4'} color={'white'} fontWeight={'bold'}>
          {number}
        </Typography>
      </StyledStep>
      <Typography color={'primary'} variant={'h5'} fontWeight={'bold'} ml={2}>
        {text}
      </Typography>
    </Box>
  );
};

const LiveText = () => (
  <>
    <Typography
      color={'primary'}
      variant={'h5'}
      fontWeight={'bold'}
      display={'inline'}
    >
      Your Marketplace is{' '}
    </Typography>
    <Typography
      fontWeight={'bold'}
      color={'secondary'}
      display={'inline'}
      variant={'h5'}
    >
      LIVE
    </Typography>
    <Typography
      color={'primary'}
      variant={'h5'}
      fontWeight={'bold'}
      display={'inline'}
    >
      !
    </Typography>
  </>
);

export const Wizard = () => {
  return (
    <Wrapper id="product">
      <Container maxWidth={'lg'}>
        <Grid container justifyContent={'center'} alignItems={'center'}>
          <Grid item sm={12} md={5}>
            <Step number={1} text="Fill out the basic form" />
            <Step number={2} text="Click create" />
            <Step number={3} text={<LiveText />} />
          </Grid>
          <Grid item sm={12} md={7}>
            <img
              src="/wizard-browser.svg"
              alt="marketplace wizard"
              width={'100%'}
            />
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};
