import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Link,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';

const StyledCard = styled(Card)<{ popular?: boolean }>`
  min-height: 449px;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
`;

interface PriceCardProps {
  planName: string;
  price: number | string;
  users: number | string;
  popular?: boolean;
  custom?: boolean;
}

export const PriceCard = (props: PriceCardProps) => {
  const { planName, price, users, popular = false, custom = false } = props;

  return (
    <StyledCard elevation={0} variant="outlined" popular={popular}>
      <CardContent>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Typography
            color={'primary'}
            variant="h4"
            fontWeight={'medium'}
            align={'center'}
          >
            {planName}
          </Typography>
          <Typography variant="h5" fontWeight={'bold'} align={'center'}>
            {price}
          </Typography>
          {!custom ? (
            <Typography variant={'caption'} color={'textSecondary'}>
              per month
            </Typography>
          ) : (
            <Box height={'19.91px'}></Box>
          )}

          <Typography
            mt={3}
            color={'primary'}
            variant="subtitle1"
            fontWeight={'medium'}
            align={'center'}
          >
            Buyers / sellers
          </Typography>
          <Typography variant="h6" fontWeight={'medium'} align={'center'}>
            {users}
          </Typography>

          <Typography
            mt={3}
            color={'primary'}
            variant="subtitle1"
            fontWeight={'medium'}
            align={'center'}
          >
            Listings
          </Typography>
          <Typography
            variant="h6"
            fontWeight={'medium'}
            align={'center'}
            mb={3}
          >
            Unlimited
          </Typography>

          {popular && (
            <Chip size={'small'} color={'secondary'} label="Most popular" />
          )}
          {/*{custom && (*/}
          {/*  <Chip*/}
          {/*    size={'small'}*/}
          {/*    // variant={'outlined'}*/}
          {/*    color={'primary'}*/}
          {/*    label="Contact for a custom quote"*/}
          {/*  />*/}
          {/*)}*/}
        </Box>
      </CardContent>
      <CardActions>
        <Link
          width={'100%'}
          href={custom ? '/#contact' : 'https://admin.8tra.de/create'}
          target={custom ? '_self' : '_blank'}
          mt={2}
          px={2}
          pb={3}
        >
          <Button fullWidth variant="outlined" color={'secondary'}>
            {custom ? 'Contact for a quote' : 'Activate free trial'}
          </Button>
        </Link>
      </CardActions>
    </StyledCard>
  );
};
