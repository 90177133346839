import * as React from 'react';
import { useRef, useState } from 'react';
import AppWrapper from '../components/AppWrapper';
import styled from 'styled-components';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { PriceCard } from '../components/Pricing/PriceCard';
import Box from '@mui/material/Box';
import { Chip, Grid, Link, ToggleButtonGroup } from '@mui/material';
import { PricingSEO } from '../components/SEO/PricingSEO';
import { FAQ } from '../components/FAQ/FAQ';
import { CustomPrice } from '../components/Pricing/CustomPrice';
import { TrustedBy } from '../components/TrustedBy/TrustedBy';
import { scrollToRef } from '../utils/scrollTo';
import FeaturesTable from '../components/Features/FeaturesTable';
import { EtrdToggleButton } from '../ui-kit/EtrdToggleButtonGroup';

const Wrapper = styled.div`
  background-color: #f9f9fb;
  padding: 5rem 0;
  margin-top: 64px;
`;

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    background-color: ${(props) => props.theme.palette.success.light};
    color: ${(props) => props.theme.palette.success.main};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  }
`;

const Pricing = () => {
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);
  const [expandEarlyAdopters, setExpandEarlyAdopters] = useState<number>(3);
  const [subscriptionType, setSubscriptionType] = useState<
    'monthly' | 'yearly'
  >('yearly');

  const handleChange = (event, newUserType) => {
    if (newUserType !== null) {
      setSubscriptionType(newUserType);
    }
  };

  return (
    <AppWrapper>
      <PricingSEO />

      <Wrapper>
        <Container maxWidth={'md'}>
          <Typography
            align={'center'}
            variant={'h4'}
            fontWeight={'bold'}
            color={'secondary'}
            mb={2}
          >
            Get Started Now <br />A flexible pricing that scales
          </Typography>
          <Typography align={'center'} mb={5} color={'secondary'}>
            Try our solution for{' '}
            <Typography fontWeight={'bold'} display={'inline'}>
              1 months
            </Typography>{' '}
            free with all features and become an{' '}
            <Link
              component="button"
              onClick={() => {
                setExpandEarlyAdopters(3);
                executeScroll();
              }}
              underline="always"
            >
              <Typography
                fontWeight={'bold'}
                display={'inline'}
                color={'secondary'}
              >
                Early Adopter!
              </Typography>
            </Link>
          </Typography>
        </Container>

        <Container maxWidth={'lg'}>
          <Box
            justifyContent={'center'}
            display={'flex'}
            width={'100%'}
            mb={10}
          >
            <ToggleButtonGroup
              color="primary"
              value={subscriptionType}
              exclusive
              onChange={handleChange}
            >
              <EtrdToggleButton value="monthly">Monthly</EtrdToggleButton>
              <EtrdToggleButton value="yearly">
                {'Yearly '}
                <StyledChip label="20% OFF" color="success" size={'small'} />
              </EtrdToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Grid container spacing={3} mb={3} justifyContent={'center'}>
            <Grid item md={4} xs={12}>
              <PriceCard
                planName="Pro"
                price={subscriptionType === 'monthly' ? '$149,99' : '$119,99'}
                users="Up to 1 350"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <PriceCard
                planName="Enterprise"
                price="---"
                users="1 350+"
                custom
              />
            </Grid>
          </Grid>
        </Container>

        <Container maxWidth={'md'}>
          <Box mt={5}>
            <CustomPrice />
          </Box>
        </Container>

        {/*<Container maxWidth={'md'}>*/}
        {/*  <Alert severity="info" variant="outlined">*/}
        {/*    Subscription currency is EUR (Euro €). Our platform uses Stripe for*/}
        {/*    payments and requires{' '}*/}
        {/*    <Link*/}
        {/*      href="https://stripe.com/en-de/connect/onboarding"*/}
        {/*      target={'_blank'}*/}
        {/*    >*/}
        {/*      <u>Stripe</u>*/}
        {/*    </Link>{' '}*/}
        {/*    account.*/}
        {/*  </Alert>*/}
        {/*</Container>*/}

        <Container maxWidth={'sm'}>
          <Box mt={5}>
            <FeaturesTable />
          </Box>
        </Container>

        <Container maxWidth={'lg'} ref={myRef}>
          <FAQ expand={expandEarlyAdopters} />
        </Container>
      </Wrapper>
      <TrustedBy />
    </AppWrapper>
  );
};

export default Pricing;
