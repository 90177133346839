import * as React from 'react';
import styled from 'styled-components';
import { Grid, Link } from '@mui/material';
import { SectionHeader } from '../SectionHeader';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { featuresList } from './featuresList';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { EtrdButton } from '../../ui-kit/EtrdButton';
import Box from '@mui/material/Box';

const Wrapper = styled.div`
  background-color: #f9f9fb;
  padding: 5rem 0;
`;

export const Features = () => {
  return (
    <Wrapper>
      <Container maxWidth={'lg'}>
        {featuresList.map((feature: any) => (
          <Grid container mb={9}>
            <Grid md={6} sm={12}>
              <SectionHeader align={'left'} title={feature.title} />
              <Typography
                variant={'h6'}
                fontWeight={'normal'}
                color={'primary'}
              >
                {feature.description}
              </Typography>
            </Grid>
            <Grid
              container
              justifyContent={'center'}
              alignItems={'center'}
              md={6}
              sm={12}
              sx={{
                mt: {
                  xs: 4,
                  md: 0,
                },
              }}
            >
              {feature.image}
            </Grid>
          </Grid>
        ))}

        <Box width="100%" justifyContent={'center'} display={'flex'}>
          <Link href="/pricing">
            <EtrdButton
              size={'large'}
              color={'primary'}
              variant={'outlined'}
              endIcon={<ArrowRightAltIcon />}
            >
              See all features
            </EtrdButton>
          </Link>
        </Box>
      </Container>
    </Wrapper>
  );
};
