import * as React from 'react';
import { AppBar, Grid, Toolbar, useScrollTrigger } from '@mui/material';
import styled from 'styled-components';
// @ts-ignore
import Logo from '/static/default.svg';
import Typography from '@mui/material/Typography';
import { EtrdButton } from '../../ui-kit/EtrdButton';
import { MobileNavigation } from './MobileNavigation';
import { Link } from 'gatsby';

const StyledAppBar = styled(AppBar)`
  &.MuiAppBar-root {
    box-shadow: none;
    background-color: #f9f9fb;
    color: #454545;

    &.shadow {
      box-shadow: 0px 3px 16px 0px rgb(0 0 0 / 10%);
    }
  }
`;

const StyledToolbar = styled(Toolbar)`
  &&&.MuiToolbar-root {
    height: 64px;
  }
`;

const isBrowser = typeof window !== 'undefined';

export const Navigation = () => {
  const HideOnScroll = (props: any) => {
    // if (!isBrowser) return <></>;
    const trigger = useScrollTrigger();

    return (
      <StyledAppBar className={trigger ? 'shadow' : ''}>
        {props.children}
      </StyledAppBar>
    );
  };

  return (
    <React.Fragment>
      <HideOnScroll>
        <StyledToolbar>
          <Grid
            container
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{
              display: {
                xs: 'none',
                lg: 'flex',
              },
            }}
          >
            <Grid item sm={3} container justifyContent={'center'}>
              <Link to="/">
                <Logo style={{ width: '110px' }} />
              </Link>
            </Grid>
            <Grid item sm={6} container justifyContent={'center'}>
              <Typography fontWeight={'bold'} mr={3}>
                <Link
                  activeStyle={{
                    color: '#EF6F6C',
                  }}
                  to="/#product"
                >
                  PRODUCT
                </Link>
              </Typography>
              <Typography fontWeight={'bold'} mr={3}>
                <Link
                  activeStyle={{
                    color: '#EF6F6C',
                  }}
                  to="/pricing"
                >
                  PRICING
                </Link>
              </Typography>
              <Typography fontWeight={'bold'} mr={3}>
                <Link to="/#usecases">USE CASES</Link>
              </Typography>
              <Typography fontWeight={'bold'} mr={3}>
                <Link
                  activeStyle={{
                    color: '#EF6F6C',
                  }}
                  to="/team/"
                >
                  TEAM
                </Link>
              </Typography>
              <Typography fontWeight={'bold'} mr={3}>
                <Link to="https://8trade.medium.com/" target={'_blank'}>
                  BLOG
                </Link>
              </Typography>
              <Typography fontWeight={'bold'} mr={3}>
                <Link to="/#contact">CONTACT</Link>
              </Typography>
            </Grid>
            <Grid item sm={3} container justifyContent={'flex-end'}>
              <Link to="https://admin.8tra.de/create">
                <EtrdButton
                  sx={{
                    display: {
                      xs: 'none',
                      md: 'flex',
                    },
                  }}
                  size={'large'}
                  color={'secondary'}
                >
                  START FOR FREE
                </EtrdButton>
                <EtrdButton
                  sx={{
                    display: {
                      xs: 'flex',
                      md: 'none',
                    },
                  }}
                  size={'small'}
                  color={'secondary'}
                >
                  START FOR FREE
                </EtrdButton>
              </Link>
            </Grid>
          </Grid>
          <MobileNavigation Logo={Logo} />
        </StyledToolbar>
      </HideOnScroll>
    </React.Fragment>
  );
};
