import * as React from 'react';
import styled from 'styled-components';
import { SectionHeader } from '../SectionHeader';
import Box from '@mui/material/Box';
import {
  EtrdAccordion,
  EtrdAccordionDetails,
  EtrdAccordionSummary,
} from '../../ui-kit/EtrdAccordion';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'gatsby';
import { useEffect, useState } from 'react';

const Wrapper = styled.div`
  padding: 5rem 0;
`;

interface FaqProps {
  expand?: number;
}

export const FAQ = ({ expand }: FaqProps) => {
  const [expanded, setExpanded] = useState<number>(expand);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    handleChange(expanded);
  }, [expanded]);

  return (
    <Wrapper>
      <Container maxWidth={'md'}>
        <SectionHeader title="Frequently asked questions" />
        <Box mt={10}>
          <EtrdAccordion expanded={expanded === 1} onChange={handleChange(1)}>
            <EtrdAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={'medium'}>
                What is Your Online Marketplace solution all about?
              </Typography>
            </EtrdAccordionSummary>
            <EtrdAccordionDetails>
              We provide a complete solution to generate your own marketplace
              alongside with the admin panel to control marketplace users,
              listings and security features. Everything with a no-code tool so
              you aren't force to have any technical knowledge.
            </EtrdAccordionDetails>
          </EtrdAccordion>

          <EtrdAccordion expanded={expanded === 2} onChange={handleChange(2)}>
            <EtrdAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={'medium'}>
                How can I buy Your solution?
              </Typography>
            </EtrdAccordionSummary>
            <EtrdAccordionDetails>
              Schedule an online call with us using our calendar in{' '}
              <Link to="#contact">
                <Typography display={'inline'} color={'primary'}>
                  Contact
                </Typography>{' '}
              </Link>
              section. We will give you an insights regarding the best pricing
              for your needs. All pricing plans you can find{' '}
              <Link to="/pricing">
                <Typography
                  display={'inline'}
                  color={'primary'}
                  aria-label="click here"
                >
                  here
                </Typography>
              </Link>
              .
            </EtrdAccordionDetails>
          </EtrdAccordion>

          <EtrdAccordion expanded={expanded === 3} onChange={handleChange(3)}>
            <EtrdAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={'medium'}>
                What does it mean to become an early adopter?
              </Typography>
            </EtrdAccordionSummary>
            <EtrdAccordionDetails>
              You can become an incredibly important to the growth of 8trade as
              you can provide us with your own very valuable insights that'll
              improve our solution. That's why we want to offer for these kinds
              of clients a few benefits that will differentiate them from
              standard clients when our early adopters program will end. When
              does it end? We don't have any specific date, but surely when we
              start feeling that it's an appropriate moment.
            </EtrdAccordionDetails>
          </EtrdAccordion>

          <EtrdAccordion expanded={expanded === 4} onChange={handleChange(4)}>
            <EtrdAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={'medium'}>
                What is the purpose of having a marketplace?
              </Typography>
            </EtrdAccordionSummary>
            <EtrdAccordionDetails>
              Having your own trading space based on your own settings increases
              level of exchanging any goods or services within your
              organization. Before, businesses had to go through tedious
              development projects that cost at least $20,000 and up to create
              your own marketplace. Now you can get fully operating system just
              within a few clicks. Additionally online marketplaces contribute
              approx. $1.7 trillion to the economy every year.{' '}
              <Link
                to="https://www.statista.com/study/44442/statista-report-b2b-e-commerce/"
                target={'_blank'}
              >
                <Typography display={'inline'} color={'primary'}>
                  Statista
                </Typography>
              </Link>{' '}
              values the global B2B ecommerce market at $14.9 billion (over 5 x
              that of the B2C market).
            </EtrdAccordionDetails>
          </EtrdAccordion>

          <EtrdAccordion expanded={expanded === 5} onChange={handleChange(5)}>
            <EtrdAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={'medium'}>
                What is chamber of commerce?
              </Typography>
            </EtrdAccordionSummary>
            <EtrdAccordionDetails>
              It is a local organization to protect and promote a community's
              business interests. Can be local, regional, state, national, or
              global. Over 13,000 chambers of commerce exist worldwide - each
              one designed to address the needs of a business within that local
              community. Goals they strive to achieve: -build communities,
              -promote those they serve, -outreach to future members, -reduce
              friction amongst businesses
            </EtrdAccordionDetails>
          </EtrdAccordion>

          <EtrdAccordion expanded={expanded === 6} onChange={handleChange(6)}>
            <EtrdAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={'medium'}>
                Do I need my own server to host my marketplace?
              </Typography>
            </EtrdAccordionSummary>
            <EtrdAccordionDetails>
              The answer is simple - no. Everything is based on our cloud
              solution which is secure and available 24/7.
            </EtrdAccordionDetails>
          </EtrdAccordion>

          <EtrdAccordion expanded={expanded === 7} onChange={handleChange(7)}>
            <EtrdAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={'medium'}>
                Will you help maintenance my marketplace?
              </Typography>
            </EtrdAccordionSummary>
            <EtrdAccordionDetails>
              Yes of course. We will help with setup and then monitoring your
              marketplace based on your needs.
            </EtrdAccordionDetails>
          </EtrdAccordion>

          <EtrdAccordion expanded={expanded === 8} onChange={handleChange(8)}>
            <EtrdAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={'medium'}>
                What if I don't want to use your solution anymore?
              </Typography>
            </EtrdAccordionSummary>
            <EtrdAccordionDetails>
              So basically you can stop paying the subscription fee.
            </EtrdAccordionDetails>
          </EtrdAccordion>
        </Box>
      </Container>
    </Wrapper>
  );
};
