import * as React from 'react';
import styled from 'styled-components';
import { SectionHeader } from '../SectionHeader';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

const Wrapper = styled.div`
  background-color: #23273d;
  padding: 5rem 0;
`;

export const Explainer = () => {
  return (
    <Wrapper>
      <Container maxWidth={'md'}>
        <Box justifyItems={'center'}>
          <SectionHeader title="What is 8trade" />
          <Typography
            color={'white'}
            align={'center'}
            fontWeight={'bold'}
            variant={'h5'}
            mb={2}
          >
            No-code cloud solution that helps you launch <br /> and operate a
            subscription-based marketplace.
          </Typography>
          <Typography color={'white'} align={'center'} variant={'h6'}>
            We'll help you set off, stimulate, and run any kind of business,
            community or an organization that will benefit from having a
            marketplace.
          </Typography>
        </Box>
      </Container>
    </Wrapper>
  );
};
