import * as React from 'react';
import '../css/global.css';
import { Navigation } from '../components/Navigation/Navigation';
import { FAQ } from '../components/FAQ/FAQ';
import { SEO } from '../components/SEO/SEO';
import AppWrapper from '../components/AppWrapper';

export default function Index() {
  return (
    <AppWrapper>
      <SEO />
      <Navigation />
      <FAQ />
    </AppWrapper>
  );
}
