import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import styled from 'styled-components';
import { ActionText } from './ActionText';
import { Grid } from '@mui/material';
// @ts-ignore
import Elements from '/static/actions-elements.svg';

const Wrapper = styled(Box)`
  background-image: url('../header-bg.svg');
  background-size: cover;
  min-height: 764px;
  display: flex;
  align-items: center;
  //margin-top: 64px;
`;

export const Header = () => {
  return (
    <Wrapper>
      <Container
        sx={{
          mt: {
            md: 20,
          },
        }}
        maxWidth={'lg'}
      >
        <Grid container>
          <Grid
            item
            md={6}
            sm={12}
            sx={{
              mt: {
                xs: 20,
                md: 0,
              },
            }}
          >
            <ActionText />
          </Grid>
          <Grid
            sx={{
              mt: {
                xs: 10,
                md: 0,
              },
              mb: {
                xs: 20,
              },
            }}
            item
            md={6}
            sm={12}
            container
            justifyContent={'center'}
          >
            <Elements style={{ maxWidth: '537px' }} />
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};
