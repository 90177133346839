import * as React from 'react';
import '../css/global.css';
import { Header } from '../components/Header/Header';
import { Navigation } from '../components/Navigation/Navigation';
import styled from 'styled-components';
import { Explainer } from '../components/Explainer/Explainer';
import { Features } from '../components/Features/Features';
import { Contact } from '../components/Contact/Contact';
import { FAQ } from '../components/FAQ/FAQ';
import { SEO } from '../components/SEO/SEO';
import AppWrapper from '../components/AppWrapper';
import { UseCase } from '../components/UseCase/UseCase';
import { TrustedBy } from '../components/TrustedBy/TrustedBy';
import { EarlyAdopters } from '../components/EarlyAdopters/EarlyAdopters';
import Container from '@mui/material/Container';
import { Testimonials } from '../components/Testimonials/Testimonials';
import { Wizard } from '../components/Features/Wizard';
import { Demo } from '../components/Features/Demo';

const StyledContainer = styled(Container)`
  background-color: #f9f9fb;
`;

export default function Index() {
  return (
    <AppWrapper>
      <SEO />
      <Navigation />
      <Header />
      <Explainer />
      <TrustedBy />
      <Wizard />
      <Demo />
      <Features />
      <StyledContainer maxWidth={false}>
        <Container maxWidth={'md'}>
          <EarlyAdopters />
        </Container>
      </StyledContainer>
      <UseCase />
      <Contact />
      <Testimonials />
      {/*<Advantages />*/}
      <FAQ />
    </AppWrapper>
  );
}
