import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import * as React from 'react';

interface TestimonialProps {
  name: string;
  company: string;
  image: string;
  testimonial: string;
  position: string;
}

const StyledTypography = styled(Typography)`
  font-style: italic;
`;

const TestimonialCard = styled.div`
  width: 100%;
  padding: 2rem;
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  overflow: hidden;
  position: relative;

  background-color: ${(props) => props.theme.palette.primary.light};
`;

const StyledImage = styled.img`
  &&& {
    height: 53px;
    width: 47px;
  }
`;

export const Testimonial = ({
  company,
  position,
  name,
  image,
  testimonial,
}: TestimonialProps) => {
  return (
    <TestimonialCard>
      <Typography mb={2} color={'primary'} fontWeight={'bold'} align={'left'}>
        {company}
      </Typography>
      <Box display={'flex'} flexDirection={'row'}>
        <Typography color={'secondary'} variant="h1" mr={2}>
          <i>"</i>
        </Typography>
        <StyledTypography color={'primary'} align={'left'}>
          {testimonial}
        </StyledTypography>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-end'}
        justifyContent={'flex-end'}
        mt={2}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'center'}
          flexWrap={'nowrap'}
        >
          <StyledImage src={image} alt="" />
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'flex-start'}
            ml={2}
          >
            <Typography
              display={'inline'}
              color={'secondary'}
              fontWeight={'bold'}
              align={'right'}
            >
              {name}
            </Typography>
            <Typography
              color={'secondary'}
              variant={'caption'}
              fontWeight={'normal'}
            >
              {position}
            </Typography>
          </Box>
        </Box>
      </Box>
    </TestimonialCard>
  );
};
