import * as React from 'react';
import styled from 'styled-components';
import { SectionHeader } from '../SectionHeader';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Link } from '@mui/material';

const Wrapper = styled.div`
  background-color: #ffffff;
  padding: 2rem 0;
`;

export const TrustedBy = () => {
  return (
    <Wrapper>
      <Container maxWidth={'md'}>
        <SectionHeader title="Trusted by" />
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          mb={4}
          sx={{
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
            m: {
              xs: 4,
              sm: 0,
            },
            justifyItems: {
              xs: 'center',
              sm: 'space-between',
            },
          }}
        >
          <Link href="https://medycyna.lublin.eu/?lang=en" target={'_blank'}>
            <img
              src="/partners/lm.png"
              alt="Lubelska Medycyna logo"
              height="80px"
            />
          </Link>
          <Link href="http://www.izba.info/" target={'_blank'}>
            <img
              src="/partners/pig.png"
              alt="Izba Gospodarcza logo"
              height="80px"
            />
          </Link>
          <Link href="https://www.klasterlogtrans.pl/" target={'_blank'}>
            <img
              src="/partners/kltpp.png"
              alt="Klaster Logistyczno-Transportowy logo"
              height="80px"
            />
          </Link>
        </Box>
      </Container>
    </Wrapper>
  );
};
