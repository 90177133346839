import * as React from 'react';
// import logo from '../../../public/logo.png';
import styled from 'styled-components';
import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import AppWrapper from '../components/AppWrapper';
import Container from '@mui/material/Container';

const StyledNumberedListWrapper = styled.div`
  background-color: transparent;
  ol {
    counter-reset: item;
    list-style-type: none;
  }
  ol > li {
    counter-increment: item;
  }
  ol > li::before {
    content: counters(item, '.') '.    ';
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
  }
  li {
    margin-bottom: 6px;
  }
  li::marker {
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
  }
`;

const Wrapper = styled.div`
  background-color: #f9f9fb;
  padding: 5rem 0;
  margin-top: 64px;
`;

const TermsAndConditions = () => {
  return (
    <AppWrapper>
      <Wrapper>
        <Container maxWidth={'lg'}>
          <StyledNumberedListWrapper>
            <Card elevation={0} variant="outlined">
              <CardContent>
                <Typography
                  color="textPrimary"
                  variant="h2"
                  align="center"
                  mb={4}
                >
                  Terms and Conditions
                </Typography>
                <Typography color="textPrimary" align="left" mb={3}>
                  <b>
                    First and foremost let us thank You for Your interest in our
                    services. At 8trade we are dedicated to provide You tools to
                    create and manage Market places meeting demands of modern
                    traders. Since every cooperation need some rules we have
                    issued this Terms and Conditions which may be updated or
                    amended by Us from time to time.
                  </b>
                </Typography>
                <Typography color="textPrimary" align="left" mb={3}>
                  By creating 8trade Account or by using any 8trade Services,
                  You agree on your own behalf/on behalf of entity You represent
                  to be bound by the this Terms and Conditions (“T&C”). If you
                  act on behalf of any entity, You represent and warrant that
                  You are fully authorized to represent such entity in contacts
                  with us. This T&C together with any other documentation
                  referred to in this T&C shall constitute a legal agreement
                  between 8trade Sp. z o.o. a company existing under the laws of
                  Republic of Poland, with registered seat in Lublin at Szkolna
                  Street 15, Lublin 20-124, Poland, registered in register of
                  entrepreneurs of National Court Register under no. 0000843332,
                  having share capital in the amount of 6.000 PLN] (“8trade”)
                  and You (“Agreement”) and govern Your use of the Service or
                  any other products provided by 8trade.
                </Typography>
                <Typography color="textPrimary" align="left" mb={3}>
                  You and 8trade are hereinafter jointly referred to as the
                  “Parties” and each separately as a “Party”.
                  <ol>
                    <li>
                      <b>Accepting this Agreement and definitions.</b>
                      <ol>
                        <li>
                          Acceptance. In order to create 8trade Account or to
                          use the Services, You must accept this T&C in full. We
                          hope that this principle is clear, because no
                          reasonable person should start cooperation without
                          knowing or understanding its rules. In case of any
                          doubts do not hesitate to contact us. If You do not or
                          cannot accept this T&C You cannot use the Services. Do
                          not download, register or use the Services in that
                          case. You agree to be bound by this T&C by: (i)
                          checking the box displayed at the end of this T&C,
                          indicating Your acceptance of the T&C, if You are
                          reading this on 8trade website or (ii) clicking an
                          “agree” or similar button where this option is
                          provided by 8trade or (iii) executing agreement made
                          between 8trade and You which states that cooperation
                          of the parties shall be governed by this T&C
                        </li>
                        <li>
                          Definitions Capitalized terms used in this T&C shall
                          have the meaning provided bellow or defined directly
                          in text (in such case defined terms shall be marked by
                          quotation marks). Using definitions makes provisions
                          of the T&C more precise and shorter (after all, no one
                          likes to read overly long legal texts).
                        </li>
                        <ol>
                          <li>
                            “Account” – shall mean account registered by You in
                            order to use the Services.
                          </li>
                          <li>
                            “Affiliate” - with respect to a Party shall mean an
                            entity which is: (i) directly or indirectly
                            controlling such Party; (ii) under the same direct
                            or indirect ownership or control as such Party; or
                            (iii) directly or indirectly owned or controlled by
                            such Party. For these purposes, one entity shall be
                            treated as being controlled by another if that other
                            entity has fifty percent (50 %) or more of the votes
                            in such entity, is able to direct its affairs and/or
                            to control the composition of its board of directors
                            or equivalent body.
                          </li>
                          <li>
                            “Business days” shall mean any day except Saturday
                            and Sunday or any other day that is a statutory
                            holiday in the Republic of Poland.
                          </li>
                          <li>
                            “Content” shall mean photos, images, videos,
                            graphics, written content, audio files, video files,
                            code, information or any data uploaded, collected,
                            generated, stored, displayed, distributed,
                            transmitted or exhibited on or in connection with
                            your Account and/or Your Marketplace.
                          </li>
                          <li>
                            “Customer” means any person that buys or sells any
                            product via Your Marketplace.
                          </li>
                          <li>
                            “GDPR” shall mean the Regulation (EU) 2016/679 of
                            the European Parliament and of the Council of 27
                            April 2016 on the protection of natural persons with
                            regard to the processing of personal data and on the
                            free movement of such data, and repealing Directive
                            95/46/EC (General data Protection Regulation).
                          </li>
                          <li>
                            “Intelligent suggestions” shall mean a process in
                            which the Software automatically finds in the
                            Software or suggest an offer or product.
                          </li>
                          <li>
                            “Intellectual property rights” shall mean all
                            patents, (including without limitation all patent
                            applications, provisionals, inventors’ certificates,
                            substitutions, extensions, reissues, renewals and
                            other similar filings), inventions (whether or not
                            patentable), industrial designs, utility models,
                            trademarks and service marks regardless of whether
                            they have been registered or otherwise formalized,
                            trade secrets, licenses, formulas, logos, domain
                            names, trade dress, techniques, knowledge, methods,
                            processes, discoveries, copyrights (including
                            without limitation right to amend and further
                            develop as well assign and sub-license ones rights),
                            rights in designs (including design registrations
                            and design rights), chip topography rights, and
                            rights in know-how, in each case whether registered
                            or unregistered and including applications for grant
                            of any of the foregoing and all rights or forms of
                            protection having equivalent or similar effect to
                            any of the foregoing which may now or at any time
                            hereafter exist anywhere in the world.
                          </li>
                          <li>
                            “Rate Card” shall mean a document featuring the
                            applicable 8trade fees, and terms of the payments.
                            The Rate Card is to be found at 8tra.de/​​pricing.
                          </li>
                          <li>
                            “Services” shall mean the services provided by
                            8trade, including providing automatic means to
                            create Your Marketplace by Yourself , hosting of the
                            online store, Intelligent suggestions
                            implementation, and any other related services that
                            may be provided or offered by 8trade from time to
                            time. Software and the Services are provided by
                            8trade via the platform, dedicated for business to
                            business (“B2B”) transactions. Software and/or
                            Services are provided by 8trade in SaaS model
                            (Software as a Service).
                          </li>
                          <li>
                            “Software” shall mean the innovative software
                            provided by 8trade for the purpose of creating Your
                            marketplace by You by automatic means with the
                            possibility of linking the marketplaces between
                            trading markets and creating common trading spaces.
                          </li>
                          <li>
                            “Party” - You and 8trade are jointly referred to as
                            the “Parties” and each separately as a “Party”.
                          </li>
                          <li>
                            “Spam” shall mean any electronic communications to
                            promote any of Your products or services.
                          </li>
                          <li>
                            “You” shall mean a Party of the Agreement other than
                            8trade – either yourself (if you act on your own
                            behalf) or an entity you are representing.
                          </li>
                        </ol>
                      </ol>
                    </li>
                    <li>
                      <b>Account and restricted use</b>
                      <ol>
                        <li>
                          You may use the Services, by registering Your 8trade
                          Account. During the registration process You will be
                          required to provide following information: name,
                          surname, telephone number, email address, company
                          name. We do not ask for this data just out of
                          curiosity. It is needed to identify You, Your needs
                          and expectations as well as to communicate with You.
                          As we live in the 21st century it goes without saying
                          that Your account access data should be protected by
                          you and secured by a secure password. You are solely
                          responsible for keeping confidentiality of Your
                          Account password and any other information regarding
                          Your Account– 8trade is not and will not be liable for
                          any loss or damage from the failure to maintain the
                          security of Your Account and/or password.
                        </li>
                        <li>
                          Although we are thrilled to start cooperation Internet
                          can be a dangerous place therefore 8trade have right
                          to reject Your application for the Account
                          registration, for any reason, at its sole discretion.
                        </li>
                        <li>
                          You may use the Services only in the course of your
                          business activities. The Services are not intended for
                          consumers (persons not carrying on business
                          activities) and consumers may not use them. Services
                          may be used only by natural or legal persons who can
                          form legally binding contracts under applicable law.
                          Sorry, but doing business requires maturity and the
                          ability to make legally binding obligations. 8trade
                          may (but is not obliged to) use various techniques to
                          verify the information provided by You. – if 8trade,
                          in its sole discretion, believes any of the provided
                          information is incorrect or false, it reserves itself
                          the right to revoke any and all licenses under the
                          Agreement or to suspend Your Account, according to
                          section 12.1 below.
                        </li>
                        <li>
                          For the same reasons as described in Section 2.3.
                          above it is prohibited to register the Account by any
                          automated methods (i.e. bots).
                        </li>
                        <li>
                          All people have their little sins but You are not
                          allowed to use the Services for any illegal or
                          unauthorized purpose. Please comply with all
                          applicable laws, regulations and rules in Your use of
                          the Service and Software and the performance of Your
                          obligations under the Agreement.
                        </li>
                        <li>
                          You cannot send Spam to any other users of the
                          Services, if it may be recognized as unsolicited
                          commercial communications.{' '}
                        </li>
                        <li>
                          We are proud of the results of our work and put a lot
                          of effort to achieve them, therefore You cannot
                          reproduce, duplicate, copy, sell, resell, modify or
                          exploit any portion of the Service without prior
                          8trade’s written consent.
                        </li>
                        <li>
                          You agree not to use the Service in any way that may
                          harm the reputation of 8trade, or which may make it
                          difficult or impossible to provide the Services to
                          other users or use the Services by other users.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Marketpalce and Software</b>
                      <ol>
                        <li>
                          You may use the Services to build up Your own
                          marketplace (“Marketplace”) for the purpose of
                          Business-to-business (B2B) transactions. The process
                          of Marketplace creation is fully automatized. You
                          shall only provide the information requested by the
                          Software, in order to automatically generate
                          Marketplace’s wireframe by the Software, accordingly
                          to the certain scheme. You do not need to invest in
                          technical infrastructure or additional resources to
                          develop the Marketplace.
                        </li>
                        <li>
                          Your Marketplace may have its own dedicated domain
                          name (agreed separately by You and provided by You).
                          The dedicated domain name should comply with all
                          applicable laws and any cannot infringe any third
                          parties rights.
                        </li>
                        <li>
                          We are always happy to help or assist You but please
                          remember that 8trade’s Services are only meant to help
                          you set up the Marketplace. 8trade does not operate
                          the Marketplace in any way and does not assume any
                          responsibility or liability for any transactions and
                          activities carried out in or via the Marketplace. To
                          this end, 8trade is not responsible for Your or any
                          third party products published and distributed in Your
                          Marketplace, as it is only acting as a passive conduit
                          for the purpose of publishing or distribution of such
                          products. 8trade takes no responsibility and has no
                          obligation to verify the products offered or published
                          in Your Marketplace in order to determine whether such
                          products or trading them may give rise to liability to
                          third parties or violate applicable law. If 8trade, in
                          its sole discretion or as a result of public
                          authorities notification or other entity reliable
                          notification, believes that publishing or distribution
                          of a certain product is illegal, it will immediately
                          take any and all actions needed to remove such product
                          from the Marketplace. In case of repeated legitimate
                          notifications, 8trade may suspend or delete Your
                          Account.
                        </li>
                        <li>
                          You will be solely responsible for managing Your
                          Marketplace as at the end of the day it is Your (and
                          nobody else) Marketplace. To make things clear:
                          <ol>
                            <li>
                              Moderation, management, maintenance, development,
                              performing the orders and request and handling all
                              inquiries, complaints or disputes arising from
                              orders or sales generated through the Marketplace
                              are Your responsibilities. 8trade has no
                              obligation to maintain any data related to Your
                              Marketplace operations other than: product data,
                              order data and Customer data stored in the 8trade
                              Software.
                            </li>
                            <li>
                              You are responsible for all activity on or in
                              connection with your Account and/or Your
                              Marketplace as well as for the Content and hereby
                              state that 8trade does not control any of the
                              abovementioned. You herby represent that You are
                              fully authorized to use as well as to grant
                              license to use the Content as described in this
                              Agreement and that such use of Content by 8trade
                              shall not infringe any rights of any third party.
                            </li>
                            <li>
                              You are responsible for drafting and publishing
                              the terms and conditions governing use of the
                              Marketplace, implementing the provisions of the
                              T&C and ensuring compliance with applicable laws.
                              Appendix No. 1 should not be regarded as legal
                              advice but as a source of inspiration for drafting
                              your own documents. 8trade does not guarantee the
                              legality of the provisions of Appendix No. 1 and,
                              to the fullest extent permitted by applicable law,
                              disclaims any liability for the consequences of
                              its use and/or implementation.{' '}
                            </li>
                            <li>
                              You are responsible for operating the Marketplace
                              in accordance with all applicable laws, rules and
                              regulations, as well as the Agreement. You are
                              solely responsible for ensuring that any personal
                              data related to the usage of the Marketplace is
                              collected and processed in accordance with all
                              applicable laws, including but not limited the
                              GDPR.
                            </li>
                          </ol>
                        </li>
                        <li>
                          Respective Marketplace may be only owned by one
                          Account owner but one Account may own multiple
                          Marketplaces.
                        </li>
                        <li>
                          By entering into this Agreement we do not and cant
                          warrant You any kind of exclusivity as our business is
                          providing services to people and entities interested
                          in running their own Marketplaces. In the light of the
                          above 8trade may provide the Services to third parties
                          including Your competitors as well as possible or
                          future competitors. 8trade does not make any promises
                          of exclusivity in any particular market segment. You
                          further acknowledge and accept that 8trade suppliers,
                          service providers, officers, directors, employees,
                          contractors or agents may also use 8trade Services and
                          that they may compete with You.
                        </li>
                        <li>
                          You hereby represent that:
                          <ol>
                            <li>
                              while using the Services You shall comply with
                              8trade policies and procedures as well as
                              applicable regulations;
                            </li>
                            <li>
                              while using the Services You shall comply with any
                              applicable law;
                            </li>
                            <li>
                              You have obtained, maintain and comply with all
                              the licenses, permissions, authorizations,
                              consents and permits that You need to carry outrun
                              your operations.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Intellectual property and know how</b>
                      <ol>
                        <li>
                          The Services provided by 8trade and all Intellectual
                          property rights therein, are the exclusive property of
                          8trade.
                        </li>
                        <li>
                          8trade does not claim any Intellectual property rights
                          to the materials You provide on Your Marketplace, but
                          by creating an Account you hereby grant 8trade a
                          non-exclusive right and license to use the names,
                          trademarks, service marks and logos associated with
                          Your Marketplace in order to promote and/or provide
                          the Service.
                        </li>
                        <li>
                          Subject to this Agreement, 8trade, grants You with a
                          non-exclusive, non-transferable, revokable, limited
                          license to access and use the Services through 8trade
                          platform, solely for the purpose of building and
                          maintaining a Marketplace.
                        </li>
                        <li>
                          The Service method of operation and its structure,
                          organization and the used source code constitute
                          valuable trade secrets of 8trade that we jealously
                          guard. You can’t directly or indirectly (i.a. through
                          any third party): (i) modify, adapt, alter, translate
                          or create derivative works from the abovementioned,
                          (ii) distribute, sublicense, lease, rent, loan or
                          otherwise transfer the abovementioned or access to the
                          abovementioned to any third party (including Your
                          Affiliates), without 8trade prior written consent.
                        </li>
                        <li>
                          You hereby grant 8trade and its Affiliates an
                          irrevocable, sublicensable, royalty-free, not limited,
                          worldwide license to reproduce Content, distribute
                          Content, create derivative works based on Content,
                          transmit Content, publicly perform Content, publicly
                          display Content solely for the purposes provided in
                          this Agreement as well as agree that 8trade has the
                          right, in its sole discretion, to share or distribute
                          the Content and to either allow or to disallow, any or
                          all web crawlers to index sites or pages or e-stores
                          hosted with 8trade.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Changes to the Service</b>
                      <ol>
                        <li>
                          The Service is based on 8trade Software which may
                          evolve, as it may be updated from time to time. 8trade
                          has the right to update or change the Software from
                          time to time which may add, modify and/or remove
                          features from the Software. The abovementioned updates
                          may be pushed automatically with little or no notice,
                          although 8trade will use reasonable efforts to inform
                          You of any changes or updates that may affect Your use
                          of the Service. 8trade is not responsible if such
                          updates affect any current hardware used or make any
                          device no longer supported or deletes some of the
                          Services elements.{' '}
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Technical support</b>
                      <ol>
                        <li>
                          Technical support for Services is y provided under the
                          condition that Your Account has not been suspended or
                          terminated.
                        </li>
                        <li>
                          You may send any questions regarding the Services to
                          8trade Support via email provided at contact@8tra.de.
                        </li>
                        <li>
                          Frequently asked questions list (“FAQ”) will be posted
                          by 8trade on its website and may be updated from time
                          to time.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Fees, taxes and payment terms</b>
                      <ol>
                        <li>
                          We are providing our services for profit therefore You
                          agree to pay 8trade the fees calculated in accordance
                          with our Rate Card which you can find here:
                          8tra.de/​​pricing. 8trade reserves the right to modify
                          the Rate Card, in its reasonable discretion from time
                          to time. 8trade will provide You at least thirty (30)
                          days prior notice before making such changes
                          effective. If said changes are unacceptable to You,
                          You may terminate this Agreement upon notice to
                          8trade, which notice must be received by 8trade prior
                          to the effective date of said changes.
                        </li>
                        <li>
                          8trade will invoice You and You agree to pay for:
                          non-refundable monthly subscription and other one-time
                          fees, in advance, including the fees for the license
                          of the Software and Services to be rendered to You by
                          or on behalf of 8trade in the following month.
                        </li>
                        <li>
                          The invoice shall be paid within fourteen (14) days of
                          the respective invoice delivery for the email address
                          indicated in the Account.
                        </li>
                        <li>
                          8trade reserves the right to suspend the Account due
                          to any payment delays after seven (7) days from the
                          payment date of the invoice until such delayed payment
                          has been paid in full.
                        </li>
                        <li>8trade does not provide any refunds.</li>
                        <li>
                          All fees under this Agreement are net amounts (exclude
                          all applicable sales, use and other applicable taxes
                          and government charges - state or foreign). You are
                          solely responsible for payment of such taxes and
                          charges, as well as any related penalties and interest
                          arising from the payment of any and all fees under
                          this Agreement (the abovementioned does not include
                          the taxes based on 8trade income).
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Confidentiality</b>
                      <ol>
                        <li>
                          You acknowledge that in connection with this Agreement
                          You may have access to Confidential Information
                          disclosed by 8trade. For the sake of good order
                          provisions of the T&C are not considered to be
                          Confidential Information.
                        </li>
                        <li>
                          Confidential Information means information and/or data
                          (in any form) concerning 8trade or its Affiliates
                          trade secrets, business operations, business
                          projections, forecasts, marketing studies, sales
                          methods, customers, supply arrangements, financial
                          arrangements, plans, strategies, ideas, commercial
                          relations, market opportunities, computer programs and
                          software methods, source code, computer code and/or
                          tangible and intangible assets which is either
                          designated as confidential when communicated by 8trade
                          to You or which by virtue of nature of that
                          information, You as a receiving person know or ought
                          reasonably to be aware is confidential.
                        </li>
                        <li>
                          During the term of Services and for one (1) year after
                          its expiry or termination, You shall keep all
                          Confidential Information disclosed by 8trade,
                          confidential and not disclose such Confidential
                          Information to any person other than its employees,
                          directors, officers, representatives, contractors,
                          subcontractors, professional advisors, in accordance
                          with this Section 8.
                        </li>
                        <li>
                          You shall use the same care to prevent disclosure of
                          the Confidential Information of 8trade as You use to
                          safeguard Your own most valuable proprietary
                          information (including, but not limited to obtaining
                          written non-disclosure agreements containing terms
                          substantially similar to the terms of this Section 8
                          from all Your personnel having access to Confidential
                          Information) but in no event less than a reasonable
                          degree of care. Your obligations hereunder shall not
                          apply to any Confidential Information which:
                          <ol>
                            <li>
                              was in the public domain other than by breach of
                              these confidentiality obligations at the time it
                              was disclosed,
                            </li>
                            <li>
                              enters the public domain other than by breach of
                              this Section 8 by You,
                            </li>
                            <li>
                              is known to You (as demonstrated by reasonably
                              documented proof) at the time of its disclosure to
                              You by 8trade,
                            </li>
                            <li>
                              is disclosed to You in good faith by a third party
                              who has the right to do so,
                            </li>
                            <li>
                              is developed by You independently (as demonstrated
                              by reasonably documented proof) of any disclosure
                              by 8trade hereunder, or
                            </li>
                            <li>
                              is disclosed by 8trade to a third party without
                              the restrictions and obligations imposed upon by
                              this Section 8.
                            </li>
                          </ol>
                        </li>
                        <li>
                          Notwithstanding the foregoing, You may use and
                          disclose Confidential Information to the extent
                          required by i) performance of rights or obligations
                          under this Agreement or ii) an order of any court or
                          other governmental authority, but only after 8trade
                          has been notified and has had the opportunity, if
                          possible, to obtain reasonable protection for such
                          Confidential Information.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Disclaimer of warranties</b>
                      <ol>
                        <li>
                          The Service and Software are provided on “as is” and
                          “as available” basis. 8trade will use its reasonable
                          efforts to keep the Service available 24/7, however
                          the Service or Software may contain inaccuracies or
                          errors that could cause failures or loss of data and
                          it may be incomplete. To the maximum extent permitted
                          by applicable law, the Service and the Software are
                          provided without warranties of any kind, whether
                          express or implied, including, but not limited to,
                          implied warranties of merchantability, fitness for a
                          particular purpose, or non-infringement. Without
                          limiting the foregoing, 8trade , its subsidiaries, and
                          its licensors do not warrant that the Services and
                          Software are accurate, reliable or correct; that the
                          Services and Software will meet Your requirements;
                          that the Services and Software will be available at
                          any particular time or location, uninterrupted or
                          secure; that any defects or errors will be corrected;
                          or that the that the Services and Software are free of
                          viruses or other harmful components. Any content
                          downloaded or otherwise obtained through the use of
                          the service is downloaded at Your own risk and You
                          will be solely responsible for any damage to Your
                          computer system or mobile device or loss of data that
                          results from such download or Your use of the Service
                          and/or Software. 8trade does not warrant, endorse,
                          guarantee, or assume responsibility for any product or
                          service advertised or offered by a third party on or
                          through Marketplace and 8trade will not be a party to
                          or in any way monitor any transaction concluded or
                          negotiated on or through Marketplace.
                        </li>
                        <li>
                          To the maximum extent permitted by applicable law, You
                          expressly acknowledge and agree that the use of
                          Software and Services is at Your sole risk.
                        </li>
                        <li>
                          In the event of Your dissatisfaction with the
                          Services, You may terminate the Agreement in
                          accordance with Section 12.2 below.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Limitation of 8trade liability</b>
                      <ol>
                        <li>
                          To the extent allowed by applicable law, in no event
                          shall 8trade, its suppliers, service providers,
                          officers, directors, employees, contractors or agents
                          be liable for personal injury or any incidental,
                          special, indirect, consequential or punitive damages,
                          including, but not limited, damages for loss of
                          profits, loss of data, business interruption or any
                          other commercial damages or losses, arising out of or
                          related to this Agreement, Your use or inability to
                          use the Services (however arising, including
                          negligence).
                        </li>
                        <li>
                          The total cumulative collective liability of 8trade,
                          its suppliers, service providers, officers, directors,
                          employees, contractors or agents for all costs, losses
                          or damages from all claims, actions or suits however
                          caused or arising from or in relation to the Service
                          and/or Software and its use will not exceed the
                          greater of (a) one hundred United States Dollars USD
                          100 or (b) all amounts paid or due from You, if any,
                          for access to or use of the Service giving rise to the
                          claim during the six (6) months immediately preceding
                          the claim (no matter when payments were actually
                          made). The foregoing limitation of liability shall
                          apply to the fullest extent permitted by law in the
                          applicable jurisdiction and is not intended to, nor
                          does it, require the relinquishment of any
                          non-waivable right afforded to You by law.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Indemnification</b>
                      <ol>
                        <li>
                          You agree to indemnify defend and hold 8trade, its
                          Affiliates, suppliers, service providers, officers,
                          directors, employees, contractors or agents harmless
                          from any claim or demand, including attorneys’ fees,
                          made by any third party due to or arising out of: i)
                          Your use of or access to the Services and/or the
                          Software, ii) Your breach of the Agreement, or Your
                          violation of any law or the rights of a third party,
                          iii) use of the Marketplace by a third party, iv) use
                          of Content. Nothing in the T&C shall be deemed to
                          exclude or limit Your liability in respect of any
                          indemnity given by You under the Agreement.
                        </li>
                        <li>
                          In no event may You enter into any settlement or like
                          agreement with a third party that affects 8trade’s
                          rights or bind 8trade in any way, without 8trade’s
                          prior written consent.
                        </li>
                        <li>
                          You waive any claim for damages of any kind or nature
                          against 8trade or its Affiliates and agree Your sole
                          and exclusive remedy for any damages (either in
                          contract or tort) is the return of the fees paid by
                          You to 8trade limited to its total value.
                        </li>
                      </ol>
                    </li>

                    <li>
                      <b>Suspension and termination</b>
                      <ol>
                        <li>
                          At its sole discretion and for any reason set forth in
                          this Section 12.1., 8trade may suspend Your Account,
                          especially if: i) 8trade believes You have been in
                          breach of any provisions of the T&C or other documents
                          constituting the Agreement or ii) if 8trade believes
                          You have engaged in fraudulent activity in connection
                          with the use of the Services or iii) if You fail to
                          make any payment when due. In the event of any such
                          suspension You will be notified and given an
                          opportunity to correct any breach being the reason of
                          such suspension. In the event such breach is not
                          corrected by You within seven (7) business days
                          ("Cooling-off period”) of the receipt of such notice
                          the Account may be terminated under Section 12.2
                          below. Fees under this Agreement will continue to
                          accrue on suspended Account as if it was not suspended
                          and You will be responsible for the payment of such
                          fees, in accordance with Section 7 above.
                        </li>
                        <li>
                          The Agreement shall remain in full force and effect
                          until it is terminated in accordance with this Section
                          12.2. The Agreement may be terminated either by
                          8trade: (i) as provided in this Agreement or (ii) with
                          immediate effect after Cooling-off period comes to an
                          end or (iii) upon thirty (30) days email notice, that
                          shall have an effect at the end of the accounting
                          period. You may terminate this Agreement upon thirty
                          (30) days email notice, that shall have an effect at
                          the end of the accounting period ..
                        </li>
                        <li>
                          In the event of expiration or termination for any
                          reason, the licenses granted under Section 4.3 above,
                          shall automatically and immediately cease and You
                          shall destroy all copies of the Software in Your
                          possession, if any.
                        </li>
                        <li>
                          In the event of expiration or termination for any
                          reason, 8trade will cease providing You with the
                          Services and You no longer be able to use Your Account
                          and Your Marketplace website will be taken offline.
                        </li>
                      </ol>
                    </li>

                    <li>
                      <b>Final provisions</b>
                      <ol>
                        <li>
                          8trade reserves the right to modify the T&C, in its
                          reasonable discretion from time to time. If the change
                          to the T&C will materially impact Your use of the
                          Services, 8trade will provide You at least thirty (30)
                          days prior notice before making such changes
                          effective. If such material changes are unacceptable
                          to You, You may terminate this Agreement upon notice
                          to 8trade, which notice must be received by no later
                          than 30 days after such changes are effective, without
                          further liability after the effective date of
                          termination. Any other (non-material) change is
                          effective as of the date the T&C are updated at
                          13.03.2022. Your continued use of the Services after
                          the effective date of the changes to the T&C, will
                          indicate acceptance by You of such changes.
                        </li>
                        <li>
                          We are independent Parties and the Agreement does not
                          constitute an agreement of partnership or joint
                          venture between the Parties.
                        </li>
                        <li>
                          The T&C as well as the Agreement shall be governed by
                          and interpreted in accordance with the laws of
                          Republic of Poland. To the extent legally permissible,
                          the exclusive place of jurisdiction for any disputes
                          arising out of the Agreement and T&C shall be
                          Szczecin, Poland.
                        </li>
                        <li>
                          The Software allows You to communicate with the
                          Customer directly through Your Account.
                        </li>
                        <li>
                          Your data shall be processed in accordance with the
                          provisions of 8trade Privacy Policy.
                        </li>
                        <li>
                          The T&C may be available in languages other than
                          English. To the extent of any inconsistencies or
                          conflicts between English version of the T&C and other
                          language version, the English version shall prevail.
                          Any disputes arising out of this T&C will be resolved
                          in English, unless otherwise determined by 8trade or
                          as required by applicable law.
                        </li>
                        <li>
                          These T&C shall be binding upon and inure to the
                          benefit of the Parties and to their respective heirs,
                          successors, permitted assigns and legal
                          representatives. 8trade is permitted to assign or
                          otherwise transfer these T&C without notice to You
                          (and without Your consent). You are not permitted to
                          assign or otherwise transfer the T&C or any of Your
                          rights or obligations hereunder, to any third party
                          (including Your Affiliates), without 8trade prior
                          written consent.
                        </li>
                        <li>
                          If any provision of these T&C proves to be invalid,
                          void, otherwise legally defective or unenforceable,
                          the remainder of the T&C shall continue in full force
                          and effect. In the event of a provision being invalid,
                          ineffective, otherwise legally defective or
                          unenforceable, such provision shall automatically be
                          replaced by a lawful provision that most nearly
                          represents Parties intentions as reconstructed from
                          the text of these T&C.
                        </li>
                        <li>
                          Sections 4.1.; 7; 8; 9; 10; 11 and 13 will survive the
                          termination or expiration of these T&C or Agreement.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </Typography>
              </CardContent>
            </Card>
          </StyledNumberedListWrapper>
        </Container>
      </Wrapper>
    </AppWrapper>
  );
};

export default TermsAndConditions;
