import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
  },
  shape: {
    borderRadius: 12,
  },
  palette: {
    primary: {
      light: '#ededf4',
      main: '#5E68A1',
      dark: '#1F2335',
      contrastText: '#fff',
    },
    secondary: {
      light: '#FDF1F1',
      main: '#EF6F6C',
      dark: '#730F0D',
      contrastText: '#fff',
    },
    error: {
      light: '#FFF2F7',
      main: '#ED2E7E',
      dark: '#C30052',
      contrastText: '#fff',
    },
    warning: {
      light: '#FFF0D4',
      main: '#F4B740',
      dark: '#A26B00',
      contrastText: '#000',
    },
    success: {
      light: '#E8F1E8',
      main: '#4CAF50',
      dark: '#00966D',
      contrastText: '#000000',
    },
    info: {
      light: '#2196F3',
      main: '#2196F3',
      dark: '#0962A9',
      contrastText: '#000000',
    },
  },
});

export default theme;
