import * as React from 'react';
import styled from 'styled-components';
import Container from '@mui/material/Container';
import { SectionHeader } from '../SectionHeader';
import { Grid, Link } from '@mui/material';
import Typography from '@mui/material/Typography';

const Wrapper = styled.div`
  background-color: #23273d;
  padding: 5rem 0;
`;

const StyledEmail = styled(Typography)`
  &::before {
    content: 'contact' '\\0040''8tra.de';
  }
`;

export const Footer = () => {
  return (
    <Wrapper>
      <Container maxWidth={'lg'}>
        <SectionHeader
          align={'center'}
          title="The best business is based on trust"
        />
        <Grid container justifyContent={'space-between'}>
          <Grid sm={6} item>
            <Typography
              variant={'caption'}
              color={'white'}
              display={'block'}
              mb={2}
            >
              8trade Sp.zo.o.
            </Typography>
            <StyledEmail
              variant={'caption'}
              color={'white'}
              display={'block'}
              // align={'right'}
            />
            <Typography variant={'caption'} color={'white'} display={'block'}>
              KRS: 0000843332
            </Typography>
            <Typography variant={'caption'} color={'white'}>
              NIP: 9462697276
            </Typography>
          </Grid>

          <Grid
            sm={6}
            item
            container
            alignContent={'stretch'}
            sx={{
              mt: {
                xs: 5,
                md: 0,
              },
            }}
          >
            <Grid
              item
              xs={12}
              container
              direction={'column'}
              sx={{
                justifyContent: {
                  xs: 'flex-start',
                  md: 'flex-end',
                },
                alignContent: {
                  xs: 'flex-start',
                  sm: 'flex-end',
                },
              }}
            >
              <Typography variant={'caption'} color={'white'} display={'block'}>
                <Link href="/terms-and-conditions" target={'_blank'}>
                  Terms and conditions
                </Link>
              </Typography>
              <Typography variant={'caption'} color={'white'} display={'block'}>
                Copyright © {new Date().getFullYear()} All rights reserved
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};
