import Typography from '@mui/material/Typography';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import * as React from 'react';

interface FeatureItemsProps {
  title: string;
  features: any[];
}

const FeatureItems = (props: FeatureItemsProps) => {
  const { title, features } = props;
  return (
    <>
      <Typography mt={2} color={'secondary'} fontWeight={'bold'}>
        {title}
      </Typography>
      {features.map((feature: any) => (
        <ListItem>
          <ListItemIcon>
            <CheckCircleIcon color={'primary'} alignmentBaseline={'baseline'} />
          </ListItemIcon>
          <ListItemText primary={feature.text} />
        </ListItem>
      ))}
    </>
  );
};

export default FeatureItems;
