import * as React from 'react';
// @ts-ignore
import Profit from '/static/feature-profit.svg';
// @ts-ignore
import Build from '/static/feature-build.svg';
// @ts-ignore
import Trade from '/static/feature-trade.svg';
// @ts-ignore
import Manage from '/static/feature-manage.svg';

export const featuresList = [
  {
    title: 'Build',
    description: `Launch your own marketplace using our web solution. Create a category tree, set up basic details, and you are good to go. You decide what is most important to you.`,
    image: <Build />,
  },
  {
    title: 'Enable B2B trade',
    description:
      'Start onboarding sellers, buyers, merchants, distributors, and manufacturers to your B2B marketplace. Allows them to trade their goods and services. Let your members be matched based on their needs.',
    image: <Trade />,
  },
  {
    title: 'Manage',
    description: `We've prepared a set of tools that will help you to manage your marketplace business in one place. Through listings management to all users moderation system. Control membership subscriptions based on Stripe integration. All these fit into a friendly, easy-to-use interface.`,
    image: <Manage />,
  },
  {
    title: 'Profit',
    description:
      'You can start monetizing your liquidity in the marketplace. Users will pay you a subscription fee in order to access your marketplace. Marketplace users will get a platform to increase their engagement. This translates to an increase in the volume of sales between them, which eventually creates new business opportunities and sales channels.',
    image: <Profit />,
  },
];
