import * as React from 'react';
import { Button, Card, CardActions, CardContent, Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

interface TeamMemberProps {
  name: string;
  title: string;
  description: string;
  linkedin?: string;
  image: string;
}

const StyledTypography = styled(Typography)`
  font-style: italic;
`;

const StyledImages = styled.div`
  position: relative;
  img {
    width: 100%;
    object-fit: cover;
    height: 300px;
  }
  img.image-hover {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    //object-fit: contain;
    opacity: 0;
    transition: opacity 0.2s;
    width: 100%;
    object-fit: cover;
    height: 300px;
  }
  &:hover img.image-hover {
    opacity: 1;
    width: 100%;
    object-fit: cover;
    height: 300px;
  }
`;

export const TeamMember = ({
  name,
  title,
  description,
  linkedin,
  image,
}: TeamMemberProps) => {
  return (
    <Card elevation={0} variant="outlined">
      <StyledImages>
        <img
          className="image-main"
          alt={`${name}'s team photo`}
          src={`./${image}.jpg`}
        />
        {image === 'adam' || image === 'wiktor' || image === 'marek' ? (
          <img
            className="image-hover"
            alt={`${name}'s funny team photo`}
            src={`./${image}-funny.jpg`}
          />
        ) : (
          <></>
        )}
      </StyledImages>

      <CardContent>
        <Typography variant="h5" fontWeight={'medium'} align={'center'}>
          {name}
        </Typography>
        <StyledTypography
          fontWeight={'medium'}
          color={'secondary'}
          align={'center'}
        >
          {title}
        </StyledTypography>
        <Typography mt={2} mb={2} display={'block'}>
          {description}
        </Typography>
        <CardActions>
          <Link width={'100%'} href={linkedin} target={'_blank'}>
            <Button fullWidth variant="outlined" startIcon={<LinkedInIcon />}>
              LinkedIn
            </Button>
          </Link>
        </CardActions>
      </CardContent>
    </Card>
  );
};
