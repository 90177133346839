import { ThemeProvider } from '@mui/material';
import { Footer } from './Footer/Footer';
import { EU } from './EU/EU';
import * as React from 'react';
import styled, {
  ThemeProvider as StyledThemeProvider,
} from 'styled-components';
import theme from '../theme';
import { Navigation } from './Navigation/Navigation';

const Wrapper = styled.div`
  svg {
    //width: 100%;
    height: auto;
    fill: currentColor;
    pointer-events: none;
  }
  a {
    text-decoration: none;
    color: unset;
  }
  &&& {html,
   body {
     scroll-behavior: smooth !important;
   }
`;
const AppWrapper = (props: any) => {
  return (
    <StyledThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <Wrapper>
          <Navigation />
          {props.children}
          <Footer />
          <EU />
        </Wrapper>
      </ThemeProvider>
    </StyledThemeProvider>
  );
};

export default AppWrapper;
