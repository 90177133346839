import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
  ToggleButtonProps,
} from '@mui/material';
import styled from 'styled-components';
import * as React from 'react';

const StyledToggleButton = styled(ToggleButton)`
  &.Mui-selected {
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
  }
  &.MuiToggleButton-root {
    border-color: ${(props) => props.theme.palette.primary.main};
    color: ${(props) => props.theme.palette.primary.main};
    &:hover {
      background-color: rgba(94, 104, 161, 0.04);
    }
  }
`;

export const EtrdToggleButton = (props: ToggleButtonProps) => {
  return (
    <StyledToggleButton disableFocusRipple disableRipple {...props}>
      {props.children}
    </StyledToggleButton>
  );
};

export const EtrdToggleButtonGroup = (props: ToggleButtonGroupProps) => {
  return <ToggleButtonGroup {...props}>{props.children}</ToggleButtonGroup>;
};

export default EtrdToggleButtonGroup;
