import Container from '@mui/material/Container';
import * as React from 'react';
import { Carousel } from 'react-responsive-carousel/lib/js';
import styled from 'styled-components';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Testimonial } from './Testimonial';
import { testimonialsList } from './testimonialsList';
import { Button, Grid, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useState } from 'react';

const Wrapper = styled.div`
  background-color: #f9f9fb;
  padding-bottom: 5rem;
  padding-top: 10rem;
`;

const StyledCarousel = styled(Carousel)`
  width: 100%;
  height: 100%;

  & .selected {
    display: flex;
    justify-content: center;
  }
`;

export const Testimonials = () => {
  const [currentTestimonialIndex, setCurrentTestimonialIndex] =
    useState<number>(0);
  const testimonialsListLength = testimonialsList.length;

  const handlePrevTestimonial = () => {
    setCurrentTestimonialIndex(currentTestimonialIndex - 1);
  };

  const handleNextTestimonial = () => {
    setCurrentTestimonialIndex(currentTestimonialIndex + 1);
  };

  const onTestimonialsChange = () => {
    if (
      currentTestimonialIndex > testimonialsListLength ||
      currentTestimonialIndex < 0
    ) {
      setCurrentTestimonialIndex(0);
    }
  };

  return (
    <Wrapper>
      <Container maxWidth={'lg'}>
        <Grid container alignItems={'center'}>
          <Grid item xs={1} container justifyContent={'center'}>
            <IconButton
              sx={{
                display: {
                  xs: 'none',
                  sm: 'flex',
                },
              }}
              disableRipple
              onClick={handlePrevTestimonial}
              aria-label="previous"
            >
              <ArrowBackIosIcon color={'secondary'} />
            </IconButton>
          </Grid>
          <Grid item xs={10} container alignItems={'center'}>
            <StyledCarousel
              autoPlay
              infiniteLoop
              showStatus={false}
              showArrows={false}
              showIndicators={false}
              selectedItem={currentTestimonialIndex}
              onChange={onTestimonialsChange}
            >
              {testimonialsList.map((props: any) => (
                <Testimonial
                  testimonial={props.testimonial}
                  company={props.company}
                  position={props.position}
                  name={props.name}
                  image={props.image}
                />
              ))}
            </StyledCarousel>
          </Grid>
          <Grid item xs={1} container justifyContent={'center'}>
            <IconButton
              sx={{
                display: {
                  xs: 'none',
                  sm: 'flex',
                },
              }}
              disableRipple
              onClick={handleNextTestimonial}
              aria-label="next"
            >
              <ArrowForwardIosIcon color={'secondary'} />
            </IconButton>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};
