import * as React from 'react';
import styled from 'styled-components';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ReactElement } from 'react';
import { SectionHeader } from '../SectionHeader';

const Wrapper = styled.div`
  background-color: #f9f9fb;
  padding: 5rem 0;
`;

const Prefix = styled.div`
  width: 11px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  left: 0px;
  top: 10px;

  background: ${(props) => props.theme.palette.secondary.main};
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
`;

const Model = ({ title, subtitle }: { title: string; subtitle: string }) => {
  return (
    <Box display={'flex'} alignItems={'center'} mb={7}>
      <Prefix />
      <Box display={'flex'} flexDirection={'column'} ml={2}>
        <Typography variant={'h5'} color={'primary'} fontWeight={'bold'}>
          {title}
        </Typography>

        <Typography color={'primary'} variant={'h6'} fontWeight={'normal'}>
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

export const Demo = () => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Wrapper>
      <Container maxWidth={'xl'}>
        <Grid container justifyContent={'center'} alignItems={'center'}>
          <Grid item xs={12} md={7} order={{ xs: 2, md: 1 }}>
            <img
              src="/demo-browser.svg"
              alt="demo marketplace example"
              width={smallScreen ? '100%' : '100%'}
            />
          </Grid>
          <Grid item xs={12} md={5} order={{ xs: 1, md: 2 }}>
            <SectionHeader
              mb={8}
              align={'center'}
              prefix={false}
              title="Flexibility"
              subheader="Create different types of marketplaces"
            />
            <Model
              title="Horizontal or Vertical marketplace"
              subtitle="Horizontal - focus on selling everything. Verticals - focus on one category, mastering It"
            />
            <Model
              title="Open or Close marketplace"
              subtitle="Open - all listings are available to the public. Close - everything is exclusively accessible to members"
            />
            <Model
              title="Paid or Free marketplace"
              subtitle="Admin have the ability to set custom recurring subscription plans for members. There can be free-access marketplaces as well"
            />
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};
