import * as React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
} from '@mui/material';
import styled from 'styled-components';

const StyledAccordion = styled(Accordion)`
  background-color: ${(props) => props.theme.palette.primary.light};
  box-shadow: none;
`;

export const EtrdAccordion = (props: AccordionProps) => {
  return <StyledAccordion {...props}>{props.children}</StyledAccordion>;
};

const StyledAccordionSummary = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    //height: 48px;
  }
`;

export const EtrdAccordionSummary = (props: AccordionSummaryProps) => {
  return (
    <StyledAccordionSummary {...props}>{props.children}</StyledAccordionSummary>
  );
};

export const EtrdAccordionDetails = (props: AccordionDetailsProps) => {
  return <AccordionDetails>{props.children}</AccordionDetails>;
};
