import * as React from 'react';
import { Helmet } from 'react-helmet';

const description =
  'Build and publish marketplace to a large audience of buyers and sellers. No technical skills required.';
const title = 'No-code marketplace builder';

export const SEO = () => {
  return (
    <Helmet
      title="8trade"
      titleTemplate={title}
      htmlAttributes={{
        lang: 'en',
      }}
    >
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content="/meta-image.png" />
      <meta property="og:url" content="https://8tra.de" />
      <meta property="og:type" content="website" />
      <meta name="image" content="/meta-image.png" />
      <meta name="description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content="/meta-image.png" />
    </Helmet>
  );
};
