import * as React from 'react';
import { useState } from 'react';
import { Dialog, Grid, IconButton } from '@mui/material';
import { EtrdButton } from '../../ui-kit/EtrdButton';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
// @ts-ignore
import Logo from '/static/default.svg';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'gatsby';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
  a {
    text-decoration: none;
    color: unset;
  }
`;

export const MobileNavigation = ({ Logo }: any) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid
      container
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{
        display: {
          sm: 'flex',
          lg: 'none',
        },
      }}
    >
      <Grid item xs={3} justifyContent={'center'}>
        <Link to="/">
          <Box
            sx={{
              width: {
                xs: '80px',
                sm: '110px',
              },
            }}
          >
            <img src="../default.svg" alt="8trade logo" height="100%" />
          </Box>
        </Link>
      </Grid>
      <Grid item xs={9} container justifyContent={'flex-end'}>
        <EtrdButton color={'secondary'}>
          <Link to="https://admin.8tra.de/create">START FOR FREE</Link>
        </EtrdButton>

        <IconButton
          size="large"
          aria-label="account of current user"
          aria-haspopup="true"
          onClick={handleClickOpen}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
      </Grid>
      <StyledDialog
        fullScreen
        open={open}
        onClose={handleClose}
        // TransitionComponent={Transition}
      >
        <Box display={'flex'} justifyContent={'space-between'} p={4}>
          <Link to="/">
            <img
              src="./default.svg"
              alt="8trade logo"
              width="110px"
              height="100%"
            />
          </Link>
          <IconButton
            size={'large'}
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          height={'100vh'}
        >
          <Typography variant="h4" fontWeight={'bold'} mb={3}>
            <Link to="/#product" onClick={() => handleClose()}>
              PRODUCT
            </Link>
          </Typography>
          <Typography
            variant="h4"
            fontWeight={'bold'}
            mb={3}
            onClick={() => handleClose()}
          >
            <Link to="/pricing/">PRICING</Link>
          </Typography>
          <Typography
            variant="h4"
            fontWeight={'bold'}
            mb={3}
            onClick={() => handleClose()}
          >
            <Link to="/#usecases">USE CASES</Link>
          </Typography>
          <Typography
            variant="h4"
            fontWeight={'bold'}
            mb={3}
            onClick={() => handleClose()}
          >
            <Link to="/team/">TEAM</Link>
          </Typography>
          <Typography
            variant="h4"
            fontWeight={'bold'}
            mb={3}
            onClick={() => handleClose()}
          >
            <Link to="https://8trade.medium.com/" target={'_blank'}>
              BLOG
            </Link>
          </Typography>
          <Typography variant="h4" fontWeight={'bold'} mb={3}>
            <Link to="/#contact" onClick={() => handleClose()}>
              CONTACT
            </Link>
          </Typography>
        </Box>
      </StyledDialog>
    </Grid>
  );
};
