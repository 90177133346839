import * as React from 'react';
import styled from 'styled-components';
import { SectionHeader } from '../SectionHeader';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.main};
  padding: 5rem 0;
`;

const StyledSectionHeader = styled(SectionHeader)`
  .MuiTypography-root {
    color: white;
  }
  & > div:first-child {
    background: white;
  }
`;

export const Contact = () => {
  return (
    <Wrapper id="contact">
      <Container maxWidth={'lg'}>
        <Container maxWidth={'md'}>
          <StyledSectionHeader title="Schedule a live demo" />
          <Typography
            variant={'h6'}
            fontWeight={'medium'}
            color={'white'}
            align={'center'}
            mb={3}
          >
            You liked what you have just read, but have some questions? <br />
            <br /> Schedule a non-binding web call with us using our calendar
            and we will show you benefits that 8trade can give to your
            organization.
          </Typography>
        </Container>

        <Box
          sx={{
            height: {
              xs: '1140px',
              md: '650px',
              lg: '650px',
            },
          }}
        >
          <iframe
            src="https://calendly.com/8trade/demo?embed_domain=8tra.de&amp;embed_type=Inline"
            width="100%"
            height="100%"
            frameBorder="0"
            title="book demo using calendly"
          ></iframe>
        </Box>
      </Container>
    </Wrapper>
  );
};
